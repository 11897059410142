<template>
  <div
    class="views_shopping_mail_pages_home bbox"
  >
    <div class="page_wrapper d-flex align-start flex-column bbox">
      <div class="sticky_group white pt-10">
        <template
          v-if="switch_search===false"
        >
          <title-module
            class="padding_x"
            @handleSearch="openSearch"
          ></title-module>
          <navigation-bar
            class="mt-20 padding_x"
            @handleChangeType="changeNavigationBar"
            :page_type="page_type"
          ></navigation-bar>
        </template>
        <search-title
          v-if="switch_search===true"
          class="padding_x py-20"
          @handleReturn="closeSearch"
        ></search-title>
      </div>
      <div class="main_group d-flex flex-column">
          <div class="sticky_group bbox flex-shrink-0 padding_x">
            <sub-navigation
              v-show="show_nav"
              :navigation="spm_nav"
              class="sub_navigation py-10"
            ></sub-navigation>
          </div>
          <router-view></router-view>
      </div>
    </div>

  </div>
</template>

<script>
import TitleModule from '@/views/ShoppingMail/Modules/Title/index.vue';
import SearchTitle from '@/views/ShoppingMail/Modules/Title/Search.vue';
import NavigationBar from '@/views/ShoppingMail/Modules/Common/NavigationBar.vue';
import SearchPage from '@/views/ShoppingMail/Pages/Search.vue';
import SubNavigation from '@/views/ShoppingMail/Modules/Common/SubNavigation/index.vue';
export default {
  components: {
    TitleModule,
    SearchTitle,
    NavigationBar,
    SearchPage,
    SubNavigation,
  },
  data(){
    return {
      switch_search: false, // 是否开启搜索
      navigation: [],
      global_search_data: undefined,
    }
  },
  computed: {
    spm_nav(){
      const {state} = this.$store;
      const {spm_nav} = state;
      return spm_nav;
    },
    page_type(){
      let page_type = '';
      const {spm_nav} = this;
      if(spm_nav && spm_nav.length){
        const first = spm_nav[0];
        page_type = first.page_type;
      }
      return page_type;
    },
    show_nav(){
      const {spm_nav} = this;
      if(spm_nav && spm_nav.length>1){
        return true;
      }
      return false;
    },
  },
  mounted(){
    this.init();
  },
  methods: {
    async init(){
      this.initNavigation();
    },
    // 初始化导航
    async initNavigation(){
      const {$route,spm_nav} = this;
      const {commit} = this.$store;
      // // console.log($route, spm_nav);
      if(spm_nav === undefined){
        const {name} = $route;
        let page_type = '';

        if(name === 'shopping_mail_featured'){
          page_type = 'featured';
        }
        if(name === 'shopping_mail_task'){
          page_type = 'task';
        }
        if(name === 'shopping_mail_teaching_package'){
          page_type = 'teaching_package';
        }

        if(name === 'shopping_mail_teaching_package_detail'){
          commit('set_spm_nav', [
            ...new Set([
              {
                type: 'teaching_package',
                package_data: {
                  id: $route.params.package_id,
                  title: '返回',
                }
              },
            ])
          ])
          return;
        }
        if(name === 'shopping_mail_task_detail'){
          commit('set_spm_nav', [
            ...new Set([
              {
                type: 'task',
                task_data: {
                  id: $route.params.task_id,
                  name: '返回',
                },
                payment: $route.params.payment,
              },
            ])
          ])
          return;
        }
        if(name === 'shopping_mail_search'){
          commit('set_spm_nav', [
            ...new Set([
              {type: 'search', search_data: {
                name: '全部'
              }}
            ])
          ])
          this.$api.shopping_mail.saveSearchFilterData({
            name: '全部',
            only: false,
            type: 'teaching_package',
            open_search: false,
            init_all: true,
            price_type: undefined
          })
          return ;
        }

        if(page_type !== ''){
          commit('set_spm_nav', [
            ...new Set([
              {type: 'page', page_type}
            ])
          ])
        }
      }
    },
    changeNavigationBar(page_type){
      this.page_type = page_type;
      this.changeNavigation([{type: 'page', page_type}]);
    },
    openSearch(){
      this.switch_search = true;
      // 备份上页路由
      this.$store.commit('set_spm_prev_router', {name:this.$route.name, params: this.$route.params});
      if(this.$store.state.spm_nav!==undefined){

        // 备份导航
        this.$store.commit('set_spm_prev_nav', [
          ...new Set([
            ...this.$store.state.spm_nav,
          ])
        ])
      }

      // 备份搜索数据
      if(this.$store.state.spm_search_filter_data !== undefined){
        this.$api.shopping_mail.saveSearchFilterBackupData(this.$store.state.spm_search_filter_data)

      }
      this.$api.shopping_mail.setTopNav({type: 'search'});
      // this.clickSearch({name: '搜索', only: false, type: 'teaching_package', open_search: true, init_all: false,})
    },
    closeSearch(){
      // 恢复路由
      this.$router.push({
        name:this.$store.state.spm_prev_router.name,
        params:this.$store.state.spm_prev_router.params,
      });
      // 恢复搜索数据
      if(this.$store.state.spm_search_filter_backup_data !== undefined){
        this.$api.shopping_mail.saveSearchFilterData(Object.assign(
          {},
          this.$store.state.spm_search_filter_backup_data,
          {loading_init: true},
        ));
      }
      //
      if(this.$store.state.spm_prev_nav!==undefined){
        // 备份导航
        this.$store.commit('set_spm_nav', [
          ...new Set([
            ...this.$store.state.spm_prev_nav,
          ])
        ])
      }
      this.switch_search = false;
      this.$nextTick(function(){
        this.initNavigation();
      })
    },

    changeNavigation(data){
      this.$set(this, 'navigation', data);
    },
    pushNavigation(item){
      this.navigation.push(item);
      // // console.log(this.navigation)
    },
    clickSearch(params){
      const {name, only, type, open_search, init_all} = params;
      this.$api.shopping_mail.pushNav({type: 'search', search_data:{
        name,
        only,
        type,
        open_search,
        init_all,
      }})
    }

  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_pages_home{
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 10px 0 0;
  position: relative;
  overflow: auto;
  // 最小宽度 1200 -310

  .page_wrapper{
    @include scrollbar;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-width: 890px;
  }
  .sticky_group{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    // background-color: #fff;
    width: 100%;
  }
  .padding_x{
    padding: {
      left: 40px;
      right: 40px;
    }
  }
  .main_group{
    flex: 1;
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: #f4f4f4;
    overflow: auto;
    .sub_navigation{
      background-color: #f4f4f4;
    }
  }
  .white{
    background-color: #fff;
  }
}
</style>
